import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import { HouseFill, ChatSquareTextFill } from 'react-bootstrap-icons'
import ComicBox from './ComicBox'

function SectionDescription() {
  return (
    <Container className="section description" id="party" fluid>
      <Container>
        <Row className="justify-content-center">
          <Col className="" lg="8">
            <h1 className="heading">Eine Kinderbuchparty ist ein besonderes Erlebnis.</h1>
          </Col>
        </Row>
        <Row className="justify-content-center mb-4">
          <Col lg="8">
            <p>
              Werde Gastgeber:in und veranstalte deine eigene Kinderbuchparty. Dabei kannst du wählen, welche Variante
              am Besten zu dir und deinen Gästen passt.
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="6" lg="5" xl="5" xxl="4" className="mb-5">
            <ComicBox className="border-warning h-100">
              <Card>
                <Card.Body>
                  <Card.Title>
                    <HouseFill className="icon text-warning" />
                    <h2>Live-Party</h2>
                  </Card.Title>
                  <Card.Text>
                    Der Klassiker. Du lädst deine Freunde und Familie ein und planst ein gemütliches Beisammensein. Das
                    kann bei dir Zuhause, im Garten oder Partyraum sein oder auch auf einem Spielplatz oder der Decke im
                    Park.
                  </Card.Text>
                  <Card.Text>
                    Eine Live-Party dauert ungefähr drei Stunden. Im Vorfeld stimmen wir uns ab, welche Altersklassen
                    und Themen euch interessieren. Zu der Party bringe ich eure Wunschbücher sowie eine Auswahl meiner
                    Lieblingsbücher mit und stelle sie euch vor.
                  </Card.Text>
                  <Card.Text>
                    Ihr verbringt eine gemeinsame Zeit und könnt in den mitgebrachten Büchern sowie dem Bücherkatalog
                    stöbern und euch austauschen.
                  </Card.Text>
                </Card.Body>
              </Card>
            </ComicBox>
          </Col>
          <Col md="6" lg="5" xl="5" xxl="4" className="mb-5">
            <ComicBox className="border-info h-100">
              <Card>
                <Card.Body>
                  <Card.Title>
                    <ChatSquareTextFill className="icon text-info" />
                    <h2>Online-Party</h2>
                  </Card.Title>
                  <Card.Text>
                    Die Alternative, wenn persönliche Treffen schwierig umzusetzen sind. Du gründest eine WhatsApp
                    Gruppe und lädst deine Freunde und Familie ein. Diese Party findet rein virtuell statt.
                  </Card.Text>
                  <Card.Text>
                    Die Online-Party geht über mehrere Tage. Zu Beginn stimmen wir uns ab, welche Altersklassen und
                    Themen euch interessieren. Ich stelle euch anhand von Fotos und Videos eure Wunschbücher sowie eine
                    Auswahl meiner Lieblingsbücher vor. Per Chat könnt ihr mir jederzeit Fragen stellen.
                  </Card.Text>
                  <Card.Text>
                    Ihr braucht nicht die ganze Zeit live dabei zu sein, sondern könnt ganz entspannt in der Gruppe und
                    im Bücherkatalog schmökern, wenn ihr die Zeit und Lust dazu habt.
                  </Card.Text>
                </Card.Body>
              </Card>
            </ComicBox>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg="8">
            <p>
              Zum Abschluss jeder Party habt ihr die Möglichkeit, die Bücher direkt bei mir zu bestellen. Ich liefere
              eure fertig sortierten Bücher zu dir als Gastgeber:in und du gibst sie an deine Party-Gäste weiter.
            </p>
            <p>
              Als Gastgeber:in kannst du dich über Buchgeschenke freuen. In Abhängigkeit des Partyumsatzes darfst du dir
              Bücher aus dem Sortiment aussuchen. Deine Gäste erhalten zu jeder Party ein kleines Gastgeschenk.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default SectionDescription
