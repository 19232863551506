import PropTypes from 'prop-types'
import * as React from 'react'
import Image from 'react-bootstrap/Image'

import girl from '../assets/img/exp-girl.webp'

const GirlImage = ({ className }) => <Image className={className} src={girl} alt="girl with book on her head" />

GirlImage.propTypes = {
  className: PropTypes.string,
}

GirlImage.defaultProps = {
  className: '',
}

export default GirlImage
