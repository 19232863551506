import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import GirlImage from './GirlImage'
import LogoImage from './LogoImage'

function LandingPortrait({ logoRef, fluid = 'xxl' }) {
  return (
    <Container className="landing-container portrait" fluid={fluid}>
      <Row className="logo">
        <Col className=" text-center align-self-end">
          <LogoImage ref={logoRef} />
        </Col>
      </Row>
      <Row className="girl">
        <Col className="text-center align-self-end">
          <GirlImage />
        </Col>
      </Row>
    </Container>
  )
}

export default LandingPortrait
