import { useEffect, useState, useRef } from 'react'

function IsInViewport(offset = 0, initSate = false) {
  const [logoVisible, setIsVisible] = useState(initSate)
  const elem = useRef(null)

  const onScroll = () => {
    if (!elem.current) {
      setIsVisible(false)
      return
    }
    const top = elem.current.getBoundingClientRect().top
    // setIsVisible(top + offset >= 0 && top - offset <= document.documentElement.clientHeight)
    const visible =
      top + offset >= 0 && (top - offset <= window.innerHeight || top - offset <= document.documentElement.clientHeight)
    setIsVisible(visible)
  }

  useEffect(() => {
    document.addEventListener('scroll', onScroll, true)
    return () => document.removeEventListener('scroll', onScroll, true)
  })

  return [logoVisible, elem]
}

export default IsInViewport
