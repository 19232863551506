import PropTypes from 'prop-types'
import * as React from 'react'
import Image from 'react-bootstrap/Image'

import logo from '../assets/img/exp-logo-colored.svg'

const LogoImage = React.forwardRef((props, ref) => <Image ref={ref} src={logo} alt="Helena's Kinderbuchparty" />)

LogoImage.propTypes = {
  className: PropTypes.string,
}

LogoImage.defaultProps = {
  className: '',
}

export default LogoImage
