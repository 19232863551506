import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import { Envelope, Whatsapp, Facebook } from 'react-bootstrap-icons'

import Signal from './Signal'
import ComicBox from './ComicBox'

function SectionContact() {
  return (
    <Container className="section contact" id="contact" fluid>
      <Container>
        <Row className="justify-content-center">
          <Col md="auto">
            <ComicBox className="container">
              <ComicBox className="frame">
                <Row>
                  <Col>
                    <h1 className="heading text-light">Gastgeber:in werden</h1>
                    <p>
                      Wenn du jetzt Lust auf deine eigene Bücherparty bekommen hast, dann kannst du mich gern über
                      Facebook, WhatsApp, Signal oder E-Mail erreichen.
                    </p>
                  </Col>
                </Row>
                <Row className="justify-content-evenly">
                  <Col xs="auto">
                    <Card>
                      <Card.Body>
                        <Card.Title>
                          <a href="https://fb.me/kinderbuch.party" rel="noreferrer" target="_blank">
                            <Facebook className="icon" />
                            <p>Facebook</p>
                          </a>
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs="auto">
                    <Card>
                      <Card.Body>
                        <Card.Title>
                          <a href="https://wa.me/4915150845945" rel="noreferrer" target="_blank">
                            <Whatsapp className="icon" />
                            <p>WhatsApp</p>
                          </a>
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs="auto">
                    <Card>
                      <Card.Body>
                        <Card.Title>
                          <a href="https://signal.me/#p/+4915150845945" rel="noreferrer" target="_blank">
                            <Signal className="icon" />
                            <p>Signal</p>
                          </a>
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs="auto">
                    <Card>
                      <Card.Body>
                        <Card.Title>
                          <a
                            href="mailto:helena@kinderbuch.party?subject=Gastgeber:in%20werden"
                            rel="noreferrer"
                            target="_blank"
                          >
                            <Envelope className="icon" />
                            <p>E-Mail</p>
                          </a>
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </ComicBox>
            </ComicBox>
          </Col>
        </Row>
      </Container>
      <Row className="divider"></Row>
    </Container>
  )
}

export default SectionContact
