import React from 'react'
import Layout from '../components/Layout'
import Navbar from '../components/Navbar'
import Landing from '../components/Landing'
import SectionProfile from '../components/SectionProfile'
import IsInViewport from '../components/hooks/IsInViewport'
import SectionDescription from '../components/SectionDescription'
import SectionServiceArea from '../components/SectionServiceArea'
import SectionContact from '../components/SectionContact'
import Seo from '../components/seo'

import '../assets/scss/styles.scss'

function IndexPage() {
  const [logoVisible, logoRef] = IsInViewport(0, true)
  return (
    <Layout>
      <Seo title="Helena's Kinderbuchparty - Deine Kinderbuchberatung für Zuhause" />
      <Navbar logoVisible={logoVisible} />
      <Landing logoRef={logoRef} />
      <SectionProfile />
      <SectionDescription />
      <SectionServiceArea />
      <SectionContact />
    </Layout>
  )
}

export default IndexPage
