import React from 'react'
import Container from 'react-bootstrap/Container'
import LandingPortrait from './LandingPortrait'
import LandingLandscape from './LandingLandscape'

class Landing extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      screenOrientation: 'portrait',
      breakpoint: 'md',
    }
  }

  isPortraitMode = () => {
    return (
      this.state.screenOrientation === 'portrait'
      // ||
      // this.state.breakpoint === 'md' ||
      // this.state.breakpoint === 'sm' ||
      // this.state.breakpoint === 'xs'
    )
  }

  setScreenRelatedStates = () => {
    if (window.matchMedia('(orientation: portrait)').matches) {
      this.setState({
        screenOrientation: 'portrait',
      })
    }

    if (window.matchMedia('(orientation: landscape)').matches) {
      this.setState({
        screenOrientation: 'landscape',
      })
    }

    this.detectBreakpoint()
  }

  componentDidMount() {
    window.addEventListener('resize', this.setScreenRelatedStates)
    this.setScreenRelatedStates()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setScreenRelatedStates)
  }

  detectBreakpoint() {
    const breakpointNames = ['xxl', 'xl', 'lg', 'md', 'sm', 'xs']
    const breakpointValues = ['1400px', '1200px', '992px', '768px', '576px', '0px']
    let i = 0
    for (const breakpointName of breakpointNames) {
      if (window.matchMedia('(min-width: ' + breakpointValues[i] + ')').matches) {
        this.setState({
          breakpoint: breakpointName,
        })
        break
      }
      i++
    }
    return null
  }

  render() {
    return (
      <Container className="landing-background" id="home" fluid>
        {this.isPortraitMode() ? (
          <LandingPortrait logoRef={this.props.logoRef} fluid="xxl" />
        ) : (
          <LandingLandscape logoRef={this.props.logoRef} fluid="xxl" />
        )}
      </Container>
    )
  }
}

export default Landing
