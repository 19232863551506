import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function SectionServiceArea() {
  return (
    <Container className="section service-area" id="area">
      <Row className="justify-content-center">
        <Col lg="8">
          <h1 className="heading">Mein Servicegebiet reicht von München bis Ingolstadt.</h1>
          <p>
            Für meine Bücherpartys bin ich im Raum München, Pfaffenhofen an der Ilm, Freising und Ingolstadt unterwegs.
            Ich besuche euch bei dir Zuhause oder an einem anderen Ort deiner Wahl.
          </p>
          <p>
            Für weiter entfernte Gebiete eignet sich eine WhatsApp Bücherparty mit Versand der Bücher. Sendet mir
            einfach eure Anfrage und wir finden eine Lösung.
          </p>
        </Col>
      </Row>
    </Container>
  )
}

export default SectionServiceArea
