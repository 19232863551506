import React from 'react'
import Container from 'react-bootstrap/Container'
import Figure from 'react-bootstrap/Figure'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ImageComicBox from './ImageComicBox'

import profile from '../assets/img/exp-profil.webp'

function SectionProfile() {
  return (
    <Container className="section profile" id="me">
      <Row className="justify-content-center">
        <Col lg="8">
          <h1 className="heading">Gemeinsam die Welt der Bücher entdecken.</h1>
          <p>
            Du bist auf der Suche nach ansprechenden und besonderen Büchern für dein Kind? Als Kinderbuchberaterin leite
            ich Bücherpartys und stelle dir, deinen Freunden und deiner Familie ein Sortiment an speziell für euch
            ausgewählten Kinderbüchern vor.
          </p>
          <ImageComicBox className="avatar mx-auto d-block" src={profile} alt="Foto von Helena Gläßer" />
          <p>
            Mein Name ist Helena. Ich bin 37 Jahre alt und lebe mit meiner Familie zwischen München und Ingolstadt im
            Herzen der Hallertau. Unsere beiden Kinder lieben es in die wundervolle Welt der Bücher einzutauchen. Die
            gemeinsame Vorlesezeit schenkt uns als Familie die Möglichkeit, den Alltag auszublenden und einfach
            füreinander da zu sein.
          </p>
          <Figure className="zitat">
            <blockquote>
              <div className="text ">
                Wie muss ein gutes Kinderbuch sein? Wenn du mich fragst, so könnte ich dir nach reiflicher Überlegung
                nur antworten: Es muss gut sein.
              </div>
            </blockquote>
            <Figure.Caption className="blockquote-footer mt-1">Astrid Lindgren</Figure.Caption>
          </Figure>
          <p>
            Persönlich finde ich es sehr schwer in dem Dschungel des riesigen Büchersortiments richtig schöne und
            kindgerechte Bücher zu finden. Wenn ich mittlerweile nach Kinderbüchern schaue, dann blättere ich gern
            ausgiebig und nehme mir die Zeit, ein Buch anzuschauen und zu lesen. Diese Begeisterung und die Freude am
            Zusammensein möchte ich gern mit euch teilen und lade euch ein dabei zu sein.
          </p>
        </Col>
      </Row>
    </Container>
  )
}

export default SectionProfile
