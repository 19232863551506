import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import GirlImage from './GirlImage'
import LogoImage from './LogoImage'

function LandingLandscape({ logoRef, fluid = 'xxl' }) {
  return (
    <Container className="landing-container landscape" fluid={fluid}>
      <Row className="h-100 align-items-center">
        <Col className="logo text-center" xs={7}>
          <LogoImage ref={logoRef} />
        </Col>
        <Col className="girl text-center" xs={5}>
          <GirlImage />
        </Col>
      </Row>
    </Container>
  )
}

export default LandingLandscape
