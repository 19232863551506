import PropTypes from 'prop-types'
import * as React from 'react'
import Image from 'react-bootstrap/Image'

const ImageComicBox = ({ src, className, alt }) => (
  <div className={'imagecomicbox ' + className}>
    <Image className={'inner'} src={src} alt={alt} fluent="true" />
  </div>
)

ImageComicBox.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
}

ImageComicBox.defaultProps = {
  className: '',
}

export default ImageComicBox
