import PropTypes from 'prop-types'
import * as React from 'react'

const ComicBox = ({ children, className }) => (
  <div className={'comicbox ' + className}>
    <div className={'inner'}>{children}</div>
  </div>
)

ComicBox.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

ComicBox.defaultProps = {
  className: '',
}

export default ComicBox
